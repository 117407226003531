import {Container, Carousel, Image} from 'react-bootstrap';
import s1 from '../assets/s1.jpg';
import s2 from '../assets/s2.jpg';

function MyCarousel({data}) {
  return (
    <Container>
        <Carousel data-bs-theme="dark" className='mt-4 mainCarousel'>
        
        <Carousel.Item>
            <Image src={s1} fluid className='w-100' />
        </Carousel.Item>
        <Carousel.Item>
            <Image src={s2} fluid className='w-100' />
        </Carousel.Item>

        {data.map((item,key)=>
           <Carousel.Item key={key}>
           <Image src={s2} fluid className='w-100' />
       </Carousel.Item>
        )}
        </Carousel>
    </Container>
  );
}

export default MyCarousel;